import React from "react";
import "./Hero.css";
import profile_img from "../../assets/profile_img.jpg";
import AnchorLink from "react-anchor-link-smooth-scroll";
/*
Contains information about the main section on the homepage aka "hero" section.
*/
const Hero = () => {
  return (
    <div id="home" className="hero">
      <div className="profile-container">
        <img src={profile_img} alt="" className="hero-img" />
      </div>
      <h1>
        <span>I'm Jimmy Nguyen,</span>
        <br />A Data Scientist/Engineer
      </h1>
      <p>
        Based in the Washington, DC area, I have over 15 years of experience
        programming and have worked in environments ranging from tiny startups
        to giant consulting firms.
      </p>
      <div className="hero-action">
        <div className="nav-connect">
          <AnchorLink className="anchor-link" offset={50} href="#contact">
            Connect With Me
          </AnchorLink>
        </div>
        <a
          href="/JimmyNguyenResume.pdf"
          download="JimmyNguyenResume.pdf"
          className="reset-a"
          locale={false}
        >
          <div className="hero-resume">My Resume</div>
        </a>
      </div>
    </div>
  );
};

export default Hero;
