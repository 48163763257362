import React from "react";
import "./About.css";
import theme_pattern from "../../assets/theme_pattern.svg";
import profile_img from "../../assets/about_profile.jpg";

const About = () => {
  return (
    <div id="about" className="about">
      <div className="about-title">
        <h1>About me</h1>
        <img src={theme_pattern} alt="" />
      </div>
      <div className="about-sections">
        <div className="about-left">
          <img src={profile_img} alt="" />
        </div>
        <div className="about-right">
          <div className="about-para">
            <p>
              Hi, I'm Jimmy Nguyen, an avid Data Scientist and Engineer. Since
              first teaching myself how to code at the age of 9, I've gained
              experience in various fields ranging from Video Game Development,
              Automation, Full-Stack Web Development, and Data
              Analysis/Science/Engineering.
            </p>
            <p>
              Having 15+ Years of Experience with programming, I've previously
              worked as a Founding Data Scientist and Data Team Lead in an
              intensely fast paced startup, revolutionizing the Department of
              Transportation industry. Currently, I'm working as a federal
              consultant, driving and refining data solutions for various
              government agencies. Looking forward, I aim to continue leading
              innovative data teams as well as further my expertise with cutting
              edge technologies.
            </p>
          </div>
          <div className="about-skills">
            <div className="about-skill">
              <p>SQL</p>
              <hr style={{ width: "70%" }} />
            </div>
            <div className="about-skill">
              <p>Python</p>
              <hr style={{ width: "100%" }} />
            </div>
            <div className="about-skill">
              <p>ETL</p>
              <hr style={{ width: "90%" }} />
            </div>
            <div className="about-skill">
              <p>AI/ML</p>
              <hr style={{ width: "50%" }} />
            </div>
            <div className="about-skill">
              <p>AWS</p>
              <hr style={{ width: "30%" }} />
            </div>
          </div>
        </div>
      </div>
      {/*
      In the tutorial, about-achievements was a child of about-section. However, that yielded 3 sections without
      overflowing to the next line. Moving about-achievements outside of about-section seems to yield the intended
      result.
      */}
      <div className="about-achievements">
        <div className="about-achievement">
          <h1>15+</h1>
          <p>YEARS OF EXPERIENCE</p>
        </div>
        <hr />
        <div className="about-achievement">
          <h1>3+</h1>
          <p>YEARS LEADERSHIP</p>
        </div>
        <hr />
        <div className="about-achievement">
          <h1>5+</h1>
          <p>PRODUCTS MANAGED</p>
        </div>
      </div>
    </div>
  );
};

export default About;
